/* Categories.css */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

.categories-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-top: 30px;
}

.category {
  flex: 0 0 100%;
  text-align: left;
  padding: 10px;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: #f8f8f8;
  color: rgb(0, 110, 193);
  font-size: 1em;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400; /* Apply the skinny appearance */
  
}

.subcategory-title {
  font-size: 1.25em; /* Or the size you prefer */
  font-weight: normal; /* If you want the titles to be bold */
  /* Any additional styles you want to apply */
}


.category h3 {
  margin: 0;
  padding-bottom: 0.5em;
  font-weight: bold; /* Apply the skinny appearance to headers */
  font-size: 1.5em;
}

.category a {
  display: block;
  text-align: left;
  margin-top: 0.5em;
  color: #00171f;
  text-decoration: none;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400; /* Apply the skinny appearance to links */
}

.category a:hover {
  text-decoration: underline;
}
  /* Medium screens (greater than or equal to 600px) */
@media screen and (min-width: 600px) {
  .category {
    flex: 0 0 calc(50% - 20px); /* Half width - adjust the 20px gap as needed */
  }
}

/* Large screens (greater than or equal to 1024px) */
@media screen and (min-width: 1024px) {
  .category {
    flex: 0 0 calc(33.333% - 20px); /* One third width - adjust the 20px gap as needed */
  }
}

/* Adjust the .categories-container if needed */
.categories-container {
  /* ... other styles ... */
  gap: 10px; /* This is the gap between categories */
}
 