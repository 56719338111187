/* Add the font import at the top of your CSS file if you're not including it in your HTML */
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,700');

.banner-container {
  display: flex;
  justify-content: center; /* Center items on the main axis */
  align-items: center; /* Center items on the cross axis */
  background-color: #f8f8f8;
  padding: 40px 20px;
  font-family: 'Roboto', sans-serif;
}

.banner-content {
  text-align: center; /* Center the text */
}

.banner-content h1 {
  margin: 0; /* Remove default margin */
  color: #277195; 
  font-size: 4em;
  font-family: 'Roboto', sans-serif; /* Change to Roboto or similar font */
  font-weight: 300; /* This is usually the lightest weight, which will give a skinny appearance */
}

.banner-content p {
  margin: 20px 0 0; /* Margin to the paragraph for spacing */
  color: #313131;
  font-size: 1.2em;
  font-family: 'Roboto', sans-serif; /* Consistency in font */
}
